import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Section, Container, SectionHeading, TextBlock, LinkButton } from "../../UI/Common"

import SearchFilters from "./SearchFilters"
import {
  sectionCls,
  containerCls,
  contentWraperCls,
  imageCls,
} from "./ProductBrandFilter.module.scss"

const ProductBrandFilter = ({
  fieldGroupName,
  searchFilters,
  topSectionHeading,
  sectionHeading,
  text,
  button,
  config,
}) => {

  const { padding } = config || {}

  return (
    <Section className={`${fieldGroupName} ${sectionCls}`}>
      <>
        <Container className={containerCls} padding={padding} mode="narrow">
          <>
            <div className={contentWraperCls}>
              {topSectionHeading && <h1 className="text-light text-capitalize text-center">{topSectionHeading}</h1> }
              <SectionHeading
                alignment="center"
                text={sectionHeading}
                mode="dark"
              />

              <SearchFilters searchFilters={searchFilters} />

              {text && <TextBlock  className="mt-5 lead" text={text} alignment="center" mode="dark" />}

              {button && (
                <LinkButton
                  link={button?.url}
                  title={button?.title}
                  target={button?.target}
                  variant="outlined-light"
                />
              )}
            </div>
          </>
        </Container>
        <StaticImage
          className={imageCls}
          style={{ position: "absolute" }}
          src="./assets/polygons.png"
          alt=""
          aria-hidden
        />
      </>
    </Section>
  )
}

export default ProductBrandFilter
