import React, { useState } from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"
import parseHTML from "html-react-parser"

import { getNumericPrice } from "../../../utils/productsHelper"

import { LinkButton } from "../../UI/Common"

import {
  MAX_PRICE_OPTIONS,
  updateSearchUrl,
  getBrandPrices,
  getAvailableMinPrices,
  getPriceKey,
  getBrandModels,
  getModelBrand,
} from "./utils"
import {
  filterWrapperCls,
  btnsWrapperCls,
  btnCls,
  btnText
} from "./SearchFilters.module.scss"

const SearchFilters = ({ searchFilters }) => {
  const { allWpPaModel, allWpPaBrand } = searchFilters
  const brands = allWpPaBrand?.nodes.filter(brand => brand.products?.nodes.length)
  const allModels = allWpPaModel?.nodes.filter(model => model.products?.nodes.length)

  const [models, setModels] = useState(allModels)
  const [maxPrices, setMaxPrices] = useState(MAX_PRICE_OPTIONS)
  const [selectedBrand, setSelectedBrand] = useState({})
  const [selectedModel, setSelectedModel] = useState({})
  const [selectedPriceMax, setSelectedPriceMax] = useState(null)
  const [priceDisabled, setPriceDisabled] = useState(false)
  const [searchUrl, setSearchUrl] = useState("/all-bikes")

  const handleBrandSelection = brand => {
    setSelectedBrand(brand)
    setSelectedModel({})
    setPriceDisabled(false)
    setSelectedPriceMax(null)

    if (brand.name === "Any") {
      setModels(allModels)
      setMaxPrices(MAX_PRICE_OPTIONS)

      return null
    }

    const brandModels = getBrandModels(brand.products)
    const brandPrices = getBrandPrices(brand.products.nodes)

    const updatedPrices = getAvailableMinPrices(
      MAX_PRICE_OPTIONS,
      brandPrices[0]
    )

    setModels(brandModels)
    setMaxPrices(updatedPrices)

    setSearchUrl(updateSearchUrl(brand, selectedPriceMax))
    return null
  }

  const handleModelSelection = model => {
    setSelectedModel(model)

    if (model.name === "Any") {
      const brandPrices =
        !selectedBrand.name || selectedBrand.name === "Any"
          ? null
          : getBrandPrices(selectedBrand.products.nodes)
      const updatedPrices = brandPrices
        ? getAvailableMinPrices(MAX_PRICE_OPTIONS, brandPrices[0])
        : MAX_PRICE_OPTIONS

      setSelectedPriceMax(null)
      setMaxPrices(updatedPrices)
      setPriceDisabled(false)
      setSearchUrl(updateSearchUrl(selectedBrand, selectedPriceMax))
      return null
    }

    const modelBrand = getModelBrand(model, brands)
    const modelPrice = getNumericPrice(model?.products?.nodes[0]?.price)

    setSelectedPriceMax(modelPrice)
    setMaxPrices([{ [modelPrice]: false }])

    const brandModels = getBrandModels(modelBrand.products)
    setModels(brandModels)
    setSelectedBrand(modelBrand)

    setPriceDisabled(true)
    const modelURL = `/products/${model.products.nodes[0].databaseId}`

    setSearchUrl(modelURL)
    return null
  }

  const handlePriceSelection = priceMax => {
    setSelectedPriceMax(priceMax)
    const url = updateSearchUrl(selectedBrand, priceMax)
    setSearchUrl(url)
  }

  const brandLabel = selectedBrand.name
    ? parseHTML(
        `Brand<br /><span class="selected-filter">${selectedBrand.name}</span>`
      )
    : "Brand"
  const modelLabel = selectedModel.name
    ? parseHTML(
        `Model<br /><span class="selected-filter">${selectedModel.name}</span>`
      )
    : "Model"
  const priceLabel = selectedPriceMax
    ? parseHTML(
        `Max Price<br /><span class="selected-filter">${
          selectedPriceMax.name === "Any"
            ? "Any"
            : `£${new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(selectedPriceMax)}`
        }
          </span>`
      )
    : "Max Price"

  return (
    <div className={filterWrapperCls}>
      <div className={btnsWrapperCls}>
        <DropdownButton
          key="dropdown-button-brands"
          id="brands-filter"
          variant="light"
          title={brandLabel}>
          <Dropdown.Item onClick={() => handleBrandSelection({ name: "Any" })}>
            {"Any"}
          </Dropdown.Item>
          {brands && React.Children.toArray(brands.map((brand, i) => (
            <Dropdown.Item
              onClick={() => handleBrandSelection(brand)}
              onKeyPress={() => handleBrandSelection(brand)}>
              {brand.name}
            </Dropdown.Item>
          )))}
        </DropdownButton>
        <DropdownButton
          key="dropdown-button-model"
          id="brands-filter"
          variant="light"
          title={modelLabel}>
          <Dropdown.Item onClick={() => handleModelSelection({ name: "Any" })}>
            {"Any"}
          </Dropdown.Item>
          {models && React.Children.toArray(models.map((model, i) => (
            <Dropdown.Item
              onClick={() => handleModelSelection(model)}
              onKeyPress={() => handleModelSelection(model)}>
              {model.name}
            </Dropdown.Item>
          )))}
        </DropdownButton>
        <DropdownButton
          key="dropdown-button-price"
          id="brands-filter"
          variant="light"
          disabled={priceDisabled}
          title={priceLabel}>
          <Dropdown.Item onClick={() => handlePriceSelection({ name: "Any" })}>
            {"Any"}
          </Dropdown.Item>
          {React.Children.toArray(maxPrices.map(item => {
            const price = getPriceKey(item)

            return (
              <Dropdown.Item
                onClick={() => handlePriceSelection(price)}
                onKeyPress={() => handlePriceSelection(price)}
                disabled={!item[price]}>
                £
                {new Intl.NumberFormat("en-GB", {
                  style: "decimal",
                }).format(price)}
              </Dropdown.Item>
            )
          }))}
        </DropdownButton>
      </div>
      <LinkButton className={btnCls} link={searchUrl}>
        <span className={btnText}>Search</span>
      </LinkButton>
    </div>
  )
}

export default SearchFilters
