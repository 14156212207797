import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import ProductBrandFilter from "./ProductBrandFilter"

export const fragment = graphql`
  fragment ProductBrandFilterFragment on WpPage_Flexlayouts_FlexibleLayouts_ProductBrandFilter {
    topSectionHeading
    sectionHeading
    text
    button {
      url
      target
      title
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

const SEARCH_FILTER_QUERY = graphql`
  fragment VariableProductFilterData on WpVariableProduct {
    id
    databaseId
    name
    price
    allPaBrand {
      nodes {
        name
      }
    }
  }
  fragment SimpleProductFilterData on WpSimpleProduct {
    id
    databaseId
    name
    price
    allPaBrand {
      nodes {
        name
      }
    }
  }
  query SEARCH_FILTER_QUERY_CB {
    allWpPaModel(limit: 50) {
      nodes {
        slug
        name
        products {
          nodes {
            nodeType
            ... on WpVariableProduct {
              ...VariableProductFilterData
            }
            ... on WpSimpleProduct {
              ...SimpleProductFilterData
            }
          }
        }
      }
    }
    allWpPaBrand(limit: 50) {
      nodes {
        slug
        name
        products {
          nodes {
            nodeType
            allPaModel {
              nodes {
                name
                slug
                id
                products {
                  nodes {
                    nodeType
                    ... on WpVariableProduct {
                      ...VariableProductFilterData
                    }
                    ... on WpSimpleProduct {
                      ...SimpleProductFilterData
                    }
                  }
                }
              }
            }
            ... on WpVariableProduct {
              ...VariableProductFilterData
            }
            ... on WpSimpleProduct {
              ...SimpleProductFilterData
            }
          }
        }
      }
    }
  }
`

export const ACFProductBrandFilter = ({ topSectionHeading, sectionHeading, text, button, config }) => {
  const data = useStaticQuery(SEARCH_FILTER_QUERY)
  const { allWpPaModel, allWpPaBrand } = data

  return (
    <ProductBrandFilter
      searchFilters={{ allWpPaModel, allWpPaBrand }}
      sectionHeading={sectionHeading}
      topSectionHeading={topSectionHeading}
      text={text}
      button={button}
      config={config}
    />
  )
}
